<template>
    <w-dialog-drawer
        v-model="isDrawer"
        :title="$trans('Rename')"
    >
        <div class="pa-4">
            <media-edit-form
                v-if="item"
                :item="item"
                @success="onSuccess"
            />
        </div>
    </w-dialog-drawer>
</template>

<script>
import MediaEditForm from '@apps/media/components/MediaEditForm/index'
export default {
    name: 'MediaEditFormDialog',
    components: { MediaEditForm },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: null
        }
    },
    watch: {
        value(flag) {
            this.isDrawer = flag
        },
        isDrawer(flag) {
            this.$emit('input', flag)
        }
    },
    data() {
        return {
            isDrawer: false
        }
    },
    methods: {
        onSuccess() {
            this.isDrawer = false
            this.$emit('success')
        }
    }
}
</script>

<style lang=scss>

</style>
