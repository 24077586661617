import { isEmpty } from './index'
import { localisationResolver } from '@/plugins/Trans'
import moment from 'moment'

export const required = value => (value && value.length ? true : localisationResolver('validation.required'))

export const requiredIf = (value, other, replace) => {
    if (isEmpty(other) && isEmpty(other)) {
        return true
    }

    if (isEmpty(other)) {
        return true
    }

    return !isEmpty(value) || localisationResolver('validation.required_if', replace)
}

export const emailValidator = value => {
    if (isEmpty(value)) {
        return true
    }

    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (Array.isArray(value)) {
        return value.every(val => re.test(String(val))) || localisationResolver('validation.email')
    }

    return re.test(String(value)) || localisationResolver('validation.email')
}

export const passwordValidator = password => {
    /* eslint-disable no-useless-escape */
    const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/
    /* eslint-enable no-useless-escape */
    const validPassword = regExp.test(password)

    return (
        // eslint-disable-next-line operator-linebreak
        validPassword || localisationResolver('validation.password')
    )
}

export const confirmedValidator = (value, target) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    value === target || localisationResolver('validation.password_confirm')

export const between = (value, min, max) => {
    const valueAsNumber = Number(value)

    return (Number(min) <= valueAsNumber && Number(max) >= valueAsNumber) || `Enter number between ${min} and ${max}`
}

export const max = (value, max) => (value && (value.length <= max)) || localisationResolver('validation.max', { max: max })
export const min = (value, min) => (value && (value.length >= min)) || localisationResolver('validation.min', { min: min })

export const integerValidator = value => {
    if (isEmpty(value)) {
        return true
    }

    if (Array.isArray(value)) {
        return value.every(val => /^-?[0-9]+$/.test(String(val)))
    }

    return /^-?[0-9]+$/.test(String(value)) || localisationResolver('validation.numeric')
}

export const regexValidator = (value, regex) => {
    if (isEmpty(value)) {
        return true
    }

    let regeX = regex
    if (typeof regeX === 'string') {
        regeX = new RegExp(regeX)
    }

    if (Array.isArray(value)) {
        return value.every(val => regexValidator(val, { regeX }))
    }

    return regeX.test(String(value)) || localisationResolver('validation.regex')
}

export const alphaValidator = value => {
    if (isEmpty(value)) {
        return true
    }

    // const valueAsString = String(value)

    return /^[A-Z]*$/i.test(String(value)) || 'The Alpha field may only contain alphabetic characters'
}

export const urlValidator = value => {
    if (value === undefined || value === null || value.length === 0) {
        return true
    }
    /* eslint-disable no-useless-escape */
    const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/

    return re.test(value) || localisationResolver('validation.url')
}

export const lengthValidator = (value, length) => {
    if (isEmpty(value)) {
        return true
    }

    return value.length === length || `The Min Character field must be at least ${length} characters`
}
export const alphaDashValidator = value => {
    if (isEmpty(value)) {
        return true
    }

    const valueAsString = String(value)

    return /^[0-9A-Z_-]*$/i.test(valueAsString) || 'All Character is not valid'
}

export const isDateBefore = (value, date, replace) => {
    if (isEmpty(value)) {
        return true
    }

    if (isEmpty(date)) {
        return true
    }

    return moment(value).isBefore(date, 'minute') || localisationResolver('validation.before', replace)
}

export const isDateAfter = (value, date, replace) => {
    if (isEmpty(value)) {
        return true
    }

    if (isEmpty(date)) {
        return true
    }

    return moment(value).isAfter(date, 'minute') || localisationResolver('validation.after', replace)
}

export const isDateInFuture = (value) => {
    if (isEmpty(value)) {
        return true
    }

    return moment(value).isAfter(moment(), 'minute') || localisationResolver('validation.future')
}
