<template>
    <w-simple-form
        :item="item"
        :disabled="isFormDisabled"
        :fields="formFields"
        :errors="formErrors"
        @update="onUpdateDocument"
    />
</template>

<script>
import { required } from '@/utils/validation'
import { updateDocument } from '@apps/media/api'
import _ from 'lodash'

export default {
    name: 'MediaEditForm',
    props: {
        item: {
            type: Object,
            default: null
        }
    },
    computed: {
        formFields() {
            return [
                {
                    type: 'v-text-field',
                    name: 'title',
                    props: {
                        outlined: true,
                        label: this.$trans('Title'),
                        rules: [
                            required
                        ]
                    }
                }
            ]
        }
    },
    data() {
        return {
            isFormDisabled: false,
            formErrors: {}
        }
    },
    methods: {
        async onUpdateDocument(data, item) {
            this.isFormDisabled = true

            const payload = {
                ...data,
                uuid: item.uuid
            }

            try {
                await updateDocument(payload)

                this.$emit('success')
            } catch (error) {
                this.formErrors = _.get(error, 'response.data.errors', {})
                this.$emit('error')
            } finally {
                this.isFormDisabled = false
            }
        },
    }
}
</script>

<style lang=scss>

</style>
